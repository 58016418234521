import React, { Component } from 'react'
import { Link } from 'gatsby'
import he from 'he'
import Fade from 'react-reveal/Fade'

class Article extends Component {

  getImageSrc(obj) {
    let src = obj && obj.localFile && obj.localFile.childImageSharp && obj.localFile.childImageSharp.original.src
    if (obj && obj.source_url.indexOf('.gif') !== -1) {
      src = obj.source_url
    }
    return src
  }

  render() {
  	const { title, slug, featured_media, acf, categories } = this.props

    return (
      <Fade bottom distance={'40px'} delay={500}>
        <div className={ `articles__item ${acf.type}` }>
          { featured_media &&
            <Link className='articles__thumb' to={`/article/${slug}`}>
              <img src={this.getImageSrc(featured_media)} alt={title} />
            </Link>
          }
          <div className='small_image-wrap'>
            <h4><Link to={`/article/${slug}`}>{he.decode(title)}</Link></h4>
            { categories.length &&
              <ul className="articles__categories">
                { categories.map((cat, i) => {
                  return (
                    <li data-category={cat.slug} key={i}>
                      { i > 0 && ', ' }
                      { cat.name }
                    </li>
                  )
                }) }
              </ul>
            }
            <Link className='btn' to={`/article/${slug}`}>Read More</Link>
          </div>
        </div>
      </Fade>
    )
  }
}

export default Article
