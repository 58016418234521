import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
//import he from 'he'
import Fade from 'react-reveal/Fade'

import SEO from '../components/seo'
import Article from '../components/cards/article'
import { LoadMore, DownArrow } from '../components/icons.js'

class JournalTemplate extends Component {

  state = {
    length: 6,
    filter: this.props.pageContext.category || 'All Themes',
    categories: ['All Themes'].concat(this.props.data.allWordpressCategory.edges.map(el => el.node.name)),
  }

  componentDidMount() {
    this.setState({ visible: !!this.props.children })
    document.querySelector('.header__logo span').innerHTML = 'Journal'
  }

  _renderFilters() {
    let { filter, categories } = this.state

    return categories.map((tag, i) => {
      let props = {
        to: tag === 'All Themes' ? `/journal/` : `/journal/${tag.toLowerCase()}`,
        className: filter === tag ? `active` : ``,
        onClick: (event) => {
          event.preventDefault()
          this.setState({ filter: event.currentTarget.innerText })

          if (window.location.pathname !== props.to) {
            window.history.pushState(window.history.state, '', props.to)
          }
        }
      }
      return (
        <li key={i}>
          <Link {...props}>{ tag }</Link>
        </li>
      )
    })
  }

  render() {
    const posts = this.props.data.allWordpressPost.edges
    let { filter, length } = this.state

    const allShown = posts.length <= length
    if (allShown) {
      length = posts.length
    }

    const loadMore = (event) => {
      event.preventDefault()
      this.setState({ length: this.state.length + 6 })
    }

    let items = posts.filter((el, i) => filter === 'All Themes' || el.node.categories.find(cat => cat.name === filter)).slice(0)

    let count = 0
    let leftCol = items.map((el, i) => {
      if ( ++count <= length && i % 2 === 0) {
        return <Article {...el.node} key={i} />
      }
      return false
    })

    count = 0
    let rightCol = items.map((el, i) => {
      if ( ++count <= length && i % 2 === 1) {
        return <Article {...el.node} key={i} />
      }
      return false
    })

    return (
      <>
        <SEO title='Journal' bodyClass='journal' description='Latest news' />
        <section className='filters'>
          <Fade bottom distance={'40px'} delay={500}>
            <div className='filters__inner'>
              <ul>
                { this._renderFilters() }
              </ul>
            </div>
          </Fade>
        </section>
        <section className='articles'>
          <div className='articles__inner'>
            <div className='articles__left'>
              { leftCol }
            </div>
            <div className='articles__right'>
              { rightCol }
            </div>
            { !allShown && (leftCol || rightCol) &&
              <div className='articles__load-more'>
                <a href="//" onClick={loadMore}>
                  <span>Load More</span>
                  <DownArrow />
                </a>
              </div>
            }
            { allShown &&
              <div className='articles__load-more'>
                No more posts available.
              </div>
            }
          </div>
        </section>
        <section className='pagination'>
          <ul>
            <li><Link to={'/clients'}><LoadMore colour={'#000'} />Clients</Link></li>
            <li><Link to={'/contact'}>Contact <LoadMore colour={'#000'} /></Link></li>
          </ul>
        </section>
      </>
    )
  }
}

export default JournalTemplate

export const journalQuery = graphql`
  query {
    allWordpressCategory(filter: { name: { ne: "Uncategorised" }}) {
      edges {
        node {
          name
        }
      }
    }
    allWordpressPost(filter: { slug: { ne: "test-post"} }) {
      edges {
        node {
          title
          slug
          categories {
            name
            slug
          }
          featured_media {
            ...original
            source_url
          }
          acf {
            type
          }
        }
      }
    }
  }
`
